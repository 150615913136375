import axios from "axios";
import router from "../../router/index";
import { prepareWorkingTypeObjects } from "./shared_functions";
import { prepareQuestionObject } from "./shared_functions";

const state = {
  quizList: [],
  decreasingList: [],
  tourList: [],
  quizSettings: {},
  ChosenWorkingTypes: [],
  showModal: false,
  isQuizStarted: false,
};
const getters = {
  // state verisini çağırmak için kullanılır
  getQuizList(state) {
    return state.quizList;
  },
  getDecreasingList(state) {
    return state.decreasingList;
  },
  getQuizSettings(state) {
    return state.quizSettings;
  },
  getShowModal(state) {
    return state.showModal;
  },
  getChosenWorkingTypes(state) {
    return state.ChosenWorkingTypes;
  },
  getIsQuizStarted(state) {
    return state.isQuizStarted;
  },
  getTourList(state) {
    return state.tourList;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setQuizList(state, payload) {
    state.quizList = payload;
    state.decreasingList = payload;
    state.isQuizStarted = false;
  },
  setQuizSettings(state, payload) {
    state.quizSettings = payload;
    localStorage.setItem("userPreferences", JSON.stringify(payload));
  },
  setShowModal(state, payload) {
    state.showModal = payload;
  },
  setChosenWorkingTypes(state, payload) {
    state.ChosenWorkingTypes = prepareWorkingTypeObjects(payload);
  },
  setIsQuizStarted(state, payload) {
    state.activePage = payload;
  },
  startQuiz(state) {
    state.isQuizStarted = true;
  },
  startTour(state) {
    // Array'i karıştır.
    const shuffled = state.decreasingList.sort(() => 0.5 - Math.random());

    // decreasingList ten random 20 tanesini al
    let selected = shuffled.slice(0, 20);

    // her mod için seçilen 20 kelime 20 özel obje olarak son listeye atılıyor.
    let question_list = prepareQuestionObject(
      selected,
      state.ChosenWorkingTypes,
      state.decreasingList
    );

    // aldığın 20 tanesini listeden kaldır
    state.decreasingList = state.decreasingList.filter(function (el) {
      return !selected.includes(el);
    });

    state.tourList = question_list;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. Axios işlemleri için
};

export default {
  state,
  getters,
  mutations,
  actions,
};
