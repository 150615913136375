import axios from "axios";

const state = {
  proActivePage: "Main",
  proTypeId: "",
  paymentToken: "",
  proErrorList: [],
  couponLoading: false,
  paymentStatus: "",
  couponSuccess: false,
};
const getters = {
  // state verisini çağırmak için kullanılır
  getProActivePage(state) {
    return state.proActivePage;
  },
  getProTypeId(state) {
    return state.proTypeId;
  },
  getPaymentToken(state) {
    return state.paymentToken;
  },
  getProErrorList(state) {
    return state.proErrorList;
  },
  getCouponLoading(state) {
    return state.couponLoading;
  },
  getPaymentStatus(state) {
    return state.paymentStatus;
  },
  getCouponSuccess(state) {
    return state.couponSuccess;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setProActivePage(state, payload) {
    state.proActivePage = payload;
    window.scrollTo(0, 0);
  },
  setProTypeId(state, payload) {
    state.proTypeId = payload;
  },
  setPaymentToken(state, payload) {
    state.paymentToken = payload;
  },
  clearProErrorList(state) {
    state.proErrorList = [];
  },
  setCouponLoading(state, payload) {
    state.couponLoading = payload;
  },
  setPaymentStatus(state, payload) {
    state.paymentStatus = payload;
  },
  setCouponSuccess(state, payload) {
    state.couponSuccess = payload;
  },
};
const actions = {
  async submitInfoForm({ commit, dispatch, state }, userPaymentData) {
    console.log(JSON.stringify(userPaymentData));
    axios
      .post("/api/CreditCardPayment/RequestPaymentToken", userPaymentData)
      .then((response) => {
        // console.log(response.data);
        commit("setPaymentToken", response.data.token);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("setProActivePage", "Card");
      });
  },
  async checkCoupon({ commit, dispatch, state }, couponCode) {
    if (couponCode == "") {
      alert("Lütfen geçerli bir kupon giriniz.");
      commit("setCouponLoading", false);
    } else {
      axios
        .get("/api/CreditCardPayment/CheckDiscountCode", {
          params: { code: couponCode, premiumTypeId: state.proTypeId },
        })
        .then((response) => {
          console.log(response);
          commit("setCouponSuccess", true);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data == "Böyle bir kupon bulunamadı") {
            state.proErrorList.push(error.response.data);
          } else {
            state.proErrorList.push("Lütfen geçerli bir kupon giriniz.");
          }
        })
        .finally(() => {
          commit("setCouponLoading", false);
        });
    }
  },
  async checkPaymentStatus({ commit, dispatch, state }, paymentId) {
    axios
      .get("/api/CreditCardPayment/CheckPaymentStatus")
      .then((response) => {
        // Initial = 0,
        // Pending = 1,
        // Success = 2,
        //  Error = 3,
        console.log(response.data);
        state.paymentStatus = response.data;
      })
      .catch((error) => {
        state.paymentStatus =
          "İşlem başarısız. Lütfen bizimle iletişime geçiniz.";
        // state.proErrorList.push(error.response.data);
      })
      .finally(() => {});
  },
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
};

export default {
  state,
  getters,
  mutations,
  actions,
};
