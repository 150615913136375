const state = {
  history: {
    title: "",
    start_length: 0,
    word_list: [],
  },
};
const getters = {
  get_history(state) {
    return state.history;
  },
};
const mutations = {
  set_history_title(state, payload) {
    state.history.title = payload;
  },
  set_history_word_list(state, payload) {
    state.history.word_list = payload;
  },
  set_history_start_length(state, payload) {
    state.history.start_length = payload;
  },
  save_history(state) {
    localStorage.setItem("history", JSON.stringify(state.history));
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
};

export default {
  state,
  getters,
  mutations,
  actions,
};
