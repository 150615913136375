<template>
  <nav id="header">
    <div class="nav-links" :style="navLinkWidth">
      <div class="logo">
        <router-link :to="$store.getters.getLocaleLink('/')" class="text-decoration-none">
          <img style="height: 50px;" src="@/assets/Home/logo.svg" alt="diziyleogren.com" />
        </router-link>
      </div>
      <ul class="routes">
        <router-link :to="$store.getters.getLocaleLink('/')" class="text-decoration-none" exact-active-class="active">
          <li>
            {{ $t("header.home") }}
          </li>
        </router-link>

        <router-link :to="$store.getters.getLocaleLink('/dizi')" class="text-decoration-none"
          exact-active-class="active">
          <li>
            {{ $t("header.series") }}
          </li>
        </router-link>
        <router-link :to="$store.getters.getLocaleLink('/film')" class="text-decoration-none"
          exact-active-class="active">
          <li>
            {{ $t("header.movies") }}
          </li>
        </router-link>
      </ul>
      <div class="search-and-account">
        <div id="input-group">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32"
            fill="var(--Gray, #B7B7B7)">
            <path
              d="M23.4166 20.1258H21.9367L21.4121 19.6318C23.3115 17.4802 24.3553 14.7333 24.3533 11.8925C24.3533 9.5404 23.6391 7.2411 22.3011 5.28539C20.9631 3.32968 19.0614 1.80538 16.8364 0.905269C14.6114 0.00515288 12.1631 -0.230359 9.80109 0.228517C7.43906 0.687392 5.26939 1.82004 3.56646 3.48324C1.86353 5.14644 0.703814 7.26548 0.233976 9.5724C-0.235862 11.8793 0.00527602 14.2705 0.926896 16.4436C1.84852 18.6166 3.40923 20.474 5.41166 21.7808C7.4141 23.0875 9.76832 23.785 12.1766 23.785C15.1927 23.785 17.9652 22.7055 20.1008 20.9125L20.6066 21.4248V22.8702L29.9732 32L32.7645 29.2739L23.4166 20.1258ZM12.1766 20.1258C7.51205 20.1258 3.74666 16.4483 3.74666 11.8925C3.74666 7.33677 7.51205 3.65924 12.1766 3.65924C16.8412 3.65924 20.6066 7.33677 20.6066 11.8925C20.6066 16.4483 16.8412 20.1258 12.1766 20.1258Z"
              fill="#B7B7B7" />
          </svg>
          <input type="text" :placeholder="$t('header.search_text')" v-model="searchText" />
        </div>
        <router-link :to="$store.getters.getLocaleLink('/hesabim')" id="account-icon" class="text-decoration-none"
          exact-active-class="active">
          <svg @click="$store.commit('setActivePage', 'main')" xmlns="http://www.w3.org/2000/svg" width="48px"
            height="48px" viewBox="0 0 48 48" fill="none">
            <path
              d="M24 24C22.35 24 20.9375 23.4125 19.7625 22.2375C18.5875 21.0625 18 19.65 18 18C18 16.35 18.5875 14.9375 19.7625 13.7625C20.9375 12.5875 22.35 12 24 12C25.65 12 27.0625 12.5875 28.2375 13.7625C29.4125 14.9375 30 16.35 30 18C30 19.65 29.4125 21.0625 28.2375 22.2375C27.0625 23.4125 25.65 24 24 24ZM12 36V31.8C12 30.95 12.219 30.1685 12.657 29.4555C13.095 28.7425 13.676 28.199 14.4 27.825C15.95 27.05 17.525 26.4685 19.125 26.0805C20.725 25.6925 22.35 25.499 24 25.5C25.65 25.5 27.275 25.694 28.875 26.082C30.475 26.47 32.05 27.051 33.6 27.825C34.325 28.2 34.9065 28.744 35.3445 29.457C35.7825 30.17 36.001 30.951 36 31.8V36H12Z"
              fill="#8887C6" />
            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#8887C6" />
          </svg>
        </router-link>

        <router-link v-if="this.$store.getters.isAuthenticated && !isPremium"
          :to="$store.getters.getLocaleLink('/premium')" class="text-decoration-none">
          <button class="upgrade-button" data="Yükselt">{{ $t("header.premium") }}</button>
        </router-link>

        <router-link v-if="!this.$store.getters.isAuthenticated"
          :to="{ path: $store.getters.getLocaleLink('/auth'), query: { authLink: 'register' } }"
          class="text-decoration-none">
          <button class="default-button" data="Kayıt Ol">{{ $t("header.register") }}</button>
        </router-link>

        <div @click="toggleIsDark()" :class="{ 'moon': isDark, 'sun': !isDark }"></div>
        <LocaleSwitcher />

        <button id="navbar-toggler" type="button" @click="hideSection">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 12H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 6H15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 18H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  </nav>
  <ul v-if="searchText" class="search-result">
    <div v-for="element in searchResultList">
      <router-link v-if="element.seasons" :to="{ path: $store.getters.getLocaleLink('/dizi/' + element.combinedUrl) }"
        class="text-decoration-none">
        <li @click="searchText = ''">{{ element.name }}</li>
      </router-link>
      <router-link v-else :to="{ path: $store.getters.getLocaleLink('/film/' + element.combinedUrl) }"
        class="text-decoration-none">
        <li @click="searchText = ''">{{ element.name }}</li>
      </router-link>
    </div>
    <div v-if="!searchResultList.length">
      <p>{{ $t("header.search_error") }}</p>
    </div>
  </ul>

  <div v-if="navbarShow" id="mobile-nav-links">
    <ul>
      <router-link :to="$store.getters.getLocaleLink('/')" class="text-decoration-none" exact-active-class="active">
        <li>{{ $t("header.home") }}</li>
      </router-link>

      <router-link :to="$store.getters.getLocaleLink('/dizi')" class="text-decoration-none" exact-active-class="active">
        <li>{{ $t("header.series") }}</li>
      </router-link>
      <router-link :to="$store.getters.getLocaleLink('/film')" class="text-decoration-none" exact-active-class="active">
        <li>{{ $t("header.movies") }}</li>
      </router-link>
    </ul>
  </div>
</template>
<script>
import LocaleSwitcher from './LocaleSwitcher.vue';
export default {
  data() {
    return {
      navbarShow: false,
      searchText: ""
    };
  },
  components: {
    LocaleSwitcher
  },
  methods: {
    hideSection() {
      this.navbarShow = !this.navbarShow
      // const elem = this.$refs.headerToggler
      // elem.click()
    },
    toggleIsDark() {
      this.$store.commit("setIsDark", !this.$store.getters.getIsDark)
    }
  },
  watch: {
    $route(to, from) {
      this.searchText = ""
      this.navbarShow = false
    }
  },
  computed: {
    searchResultList() {
      let tmp = this.$store.getters.getDOData.data.series
      if (this.searchText != "") {
        tmp = tmp.filter(x =>
          x.name.toLowerCase().match(this.searchText.toLowerCase())
        );
      }

      let tmp2 = this.$store.getters.getDOData.data.movies
      if (this.searchText != "") {
        tmp2 = tmp2.filter(x =>
          x.name.toLowerCase().match(this.searchText.toLowerCase())
        );
      }

      let result = tmp.concat(tmp2)

      return result
    },
    navLinkWidth() {
      if (!this.$store.getters.isAuthenticated) {
        return { 'max-width': '1104px', 'margin-left': '170px' }
      } else if (this.$store.getters.isAuthenticated && this.isPremium) {
        return { 'max-width': '960px' }
      } else if (this.$store.getters.isAuthenticated && !this.isPremium) {
        return { 'max-width': '1104px', 'margin-left': '170px' }
      }
    },
    isPremium() {
      return this.$store.getters.getUserData.isPremium
    },
    isDark() {
      return this.$store.getters.getIsDark
    }
  },
  created() {
    console.log(this.$store.getters.getLocaleLink("/dizi"))
  }
};
</script>
<style scoped>
#header {
  width: 100%;
  height: 96px;
  background-color: #fff;
  -webkit-transition: backgroundColor 1s linear !important;
  transition: backgroundColor 1s linear !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

#header .nav-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  justify-content: space-between;
}

#header .nav-links ul {
  border: none;
  display: flex;
  gap: 30px;
  padding: 0;
  margin: 0;
}

#header .nav-links ul li {
  color: var(--Black-Gray, #696969);
  text-align: center;
  /* WEB/Label Large */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  list-style-type: none;
}

#header .nav-links ul li:hover {
  color: #8887c6 !important;
  font-weight: 700 !important;
  border-bottom: 2px solid var(--Primary, #8887c6);
}

.active li {
  color: #8887c6 !important;
  font-weight: 700 !important;
  border-bottom: 2px solid var(--Primary, #8887c6);
}

#header .nav-links .search-and-account {
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-left: 50px;
}

#mobile-nav-links {
  width: 100%;
  background-color: #dad3d3;
}

#mobile-nav-links ul {
  list-style-type: none;
  margin: 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

#mobile-nav-links ul li {
  color: #6a6a6a;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

#input-group {
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Gray, #f8f8f8);
  justify-self: flex-end;
}

#input-group svg {
  margin-top: 8px;
  margin-left: 16px;
}

#input-group input {
  width: 100%;
  margin-left: 16px;
  border: none;
  background: var(--Light-Gray, #f8f8f8);
}

#input-group input:focus {
  outline: none;
}

#input-group input::placeholder {
  color: var(--Gray, #b7b7b7);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#account-icon {
  stroke-width: 1px;
  stroke: var(--Primary, #8887c6);
  cursor: pointer;
  height: 100%;
}

.search-result {
  border-radius: 0px 0px 24px 24px;
  background: #FFF;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  list-style-type: none;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
}

.search-result li {
  cursor: pointer;

  color: #6a6a6a;
}

.search-result li:hover {
  color: #7675d5;
}

.default-button {
  width: 144px;
  height: 48px;
  background-color: #8887c6;
  transition: background-color 0.5s;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.upgrade-button {
  width: 144px;
  height: 48px;
  background-color: #B26C6C;
  transition: background-color 0.5s;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.default-button:hover {
  background-color: #7675d5;
}

.upgrade-button:hover {
  background-color: #b36060;
}


#navbar-toggler {
  cursor: pointer;
  display: none;
  width: 48px;
  height: 100%;
  border: none;
  border-radius: 4px;
  background: var(--Primary, #8887c6);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
  align-self: center;
}

@media (max-width: 1240px) {
  #header .nav-links {
    margin-left: 0px !important;
  }

  #header .nav-links ul {
    display: none;
  }

  #navbar-toggler {
    display: block;
    height: 48px;
  }

  .dropdown-content {
    top: 36px !important;
  }

}

@media (max-width: 1000px) {
  #header {
    height: 70px;
  }

  #header .nav-links {
    width: 90%;
    margin-left: 0px !important;
    justify-content: space-between;
  }

  #header .nav-links .logo img {
    height: 36px !important;
  }

  #input-group {
    display: none;
  }

  #navbar-toggler {
    margin: 0px;
  }

  .default-button {
    display: none;
  }

  .upgrade-button {
    display: none;

  }

  #header .nav-links .search-and-account {
    gap: 12px;
    margin-left: 20px;
  }
}

/* Change the background color of the dropdown button when the dropdown content is shown */
#account-icon path {
  transition: fill 0.5s;
}
</style>