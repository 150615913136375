<template>
    <div class="my-wrapper">
        <Vue3Lottie :animationData="Loading" :height="200" :width="200" />
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie'
import Loading from '@/assets/loading-page.json'
export default {
    data() {
        return {
            Loading
        }
    },
    components: {
        Vue3Lottie
    },
    created() {
    },
    computed: {
    },

}

</script>