import { createStore } from "vuex";
import home from "./modules/home";
import seriesDetail from "./modules/seriesDetail";
import auth from "./modules/auth";
import account from "./modules/account";
import quiz from "./modules/quiz";
import premium from "./modules/premium";

export default createStore({
  state: {
    // site geneli store lar
    seriesList: [],
    moviesList: [],
  },
  getters: {
    getSeriesList(state) {
      return state.seriesList;
    },
    getMoviesList(state) {
      return state.moviesList;
    },
  },
  mutations: {
    // state güncellemek için çalıştırılır. Senkron çalışır
    UpdateSeriesList(state, payload) {
      state.seriesList = payload;
    },
    UpdateMoviesList(state, payload) {
      state.moviesList = payload;
    },
  },
  actions: {},
  modules: {
    home,
    seriesDetail,
    auth,
    account,
    quiz,
    premium,
  },
});
