import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Vue3GoogleLogin from "vue3-google-login";
import { createHead } from "@unhead/vue";
import { Head } from "@unhead/vue/components";
import Loading from "./components/Shared/Loading";
import Reklam from "./components/Shared/Reklam";
import ScriptX from "vue-scriptx";
import Ads from "vue-google-adsense";
import "./assets/css/main.css";
import "./assets/css/dark.css";
import i18n from "./i18n";

// import i18n from "./i18n";

// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// axios.defaults.baseURL = "https://cors-anywhere.herokuapp.com/https://test.diziyleogren.com";
// axios.defaults.baseURL = "http://testapi.diziyleogren.com";
axios.defaults.baseURL = "https://api.diziyleogren.com";
// axios.defaults.headers.common["x-cors-api-key"] =
//  "temp_7fc70ed3a7bdd1f9dc182ed6f6e4380f";

const head = createHead();

createApp(App)
  .use(store)
  .use(router)
  .use(Vue3GoogleLogin, {
    clientId:
      "56376935110-9ghtf91avv0k524h34ji5u3blb15bkqk.apps.googleusercontent.com",
    //"288948594060-nl6192n69m9op5moq6njgs1pifjlt4i3.apps.googleusercontent.com",
  })
  .use(head)
  .use(ScriptX)
  .use(Ads.Adsense)
  .use(i18n)
  .component("Head", Head)
  .component("Loading", Loading)
  .component("Reklam", Reklam)
  .mount("#app");
