<template>
    <div class="responsive-ads" v-if="!this.$store.getters.getUserData.IsPremium">
        <slot></slot>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },

    mounted() {
    },
    destroyed() {
    }

}
</script>
<style></style>