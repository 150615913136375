const state = {
  activePage: "main",
  selectedListId: 943,
  editOption: false,
  showNewListModal: false,
};
const getters = {
  // state verisini çağırmak için kullanılır
  getActivePage(state) {
    return state.activePage;
  },
  getSelectedListId(state) {
    return state.selectedListId;
  },
  getEditOption(state) {
    return state.editOption;
  },
  getShowNewListModal(state) {
    return state.showNewListModal;
  },
};
const mutations = {
  // state güncellemek için çalıştırılır. Senkron çalışır
  setActivePage(state, payload) {
    state.activePage = payload;
    window.scrollTo(0, 0);
  },
  setSelectedListId(state, payload) {
    state.selectedListId = payload;
  },
  setEditOption(state, payload) {
    state.editOption = payload;
  },
  setShowNewListModal(state, payload) {
    state.showNewListModal = payload;
  },
};
const actions = {
  // state güncellemek için çalıştırılır. Asenkron çalışır. DB işlemleri için
};

export default {
  state,
  getters,
  mutations,
  actions,
};
