<template>
    <div class="locale-switcher">
        <div class="locale-changer">
            <select class="select-box" v-model="locale" @change="changeLocale()">
                <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
                    {{ locale }}
                </option>
            </select>
        </div>
    </div>
</template>
<script>
import vSelect from "vue-select"
export default {
    data() {
        return {
            locale: "tr"
        };
    },
    created() {
        this.locale = this.$i18n.locale
    },
    components: {
        vSelect
    },
    methods: {
        changeLocale() {
            this.$i18n.locale = this.locale
            localStorage.setItem("DOLocale", this.locale);
            this.$router.push({ path: this.$store.getters.getLocaleLink(this.$route.path) });
        }
    }


};

</script>
<style scoped>
.locale-switcher {
    position: absolute;
    top: 20%;
    right: 24px;
}

.locale-changer .select-box {
    border-radius: 8px;
    padding: 16px 16px;
    box-shadow: 0px 4px 4px 0px #D9D9D9;
    outline: none;
    border: none;
    text-transform: uppercase;
    color: #8887c6;
    cursor: pointer;
}

img {
    width: 30px;
    height: 30px;
}

@media (max-width: 1200px) {
    .locale-switcher {
        position: relative;
        justify-self: flex-start;
        margin: 0px;
        padding: 0px;
        right: 0;
        top: 0;
    }

    .locale-changer .select-box {
        padding: 8px 8px;
    }
}
</style>