import { createRouter, createWebHistory, RouterView } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    component: RouterView,
    children: [
      {
        path: "",
        name: "TRhome",
        component: Home,
      },
      {
        path: "dizi",
        name: "TRSeriesList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "SeriesList" */ "../views/SeriesList.vue"
          ),
      },
      {
        path: "film",
        name: "TRMoviesList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MoviesList.vue"
          ),
      },
      {
        path: "dizi/:url",
        name: "TRSeriesDetail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "SeriesDetail" */ "../views/SeriesDetail.vue"
          ),
      },
      {
        path: "film/:url",
        name: "TRMovieDetail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MovieDetail.vue"
          ),
      },
      {
        path: "auth",
        name: "TRauth",
        beforeEnter: (to, from, next) => {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next("/hesabim");
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/Auth.vue"),
      },
      {
        path: "hesabim",
        name: "TRhesabim",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "hesabim" */ "../views/Account.vue"),
      },
      {
        path: "liste/:url",
        name: "TRGeneratedList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "GeneratedList" */ "../views/GeneratedList.vue"
          ),
      },
      {
        path: "bilinenler-listesi/:url",
        name: "TRKnownList",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "KnownList" */ "../views/KnownList.vue"),
      },
      {
        path: "quiz",
        name: "TRquiz",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "QuizMain" */ "../views/QuizMain.vue"),
      },
      {
        path: "iletisim",
        name: "TRiletisim",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      },
      {
        path: "hakkimizda",
        name: "TRhakkimizda",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "About" */ "../views/About.vue"),
      },
      {
        path: "gizlilikPolitikasi",
        name: "TRgizlilikPolitikasi",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/PrivacyPolicy.vue"
          ),
      },
      {
        path: "kullanimKosullari",
        name: "TRkullanimKosullari",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/UseConditions.vue"
          ),
      },
      {
        path: "nasil-calisir",
        name: "TRNasilCalisir",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/UseInstructions.vue"
          ),
      },
      {
        path: "atiflar",
        name: "TRatiflar",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/Atiflar.vue"
          ),
      },
      {
        path: "blog",
        name: "TRblog",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "BlogMain" */ "../views/Blog/Main.vue"),
      },
      {
        path: "blog/dizi-ile-ingilizce-ogrenmek-ayrintili-rehber",
        name: "TRblogRehber",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "BlogRehber" */ "../views/Blog/Rehber.vue"
          ),
      },
      {
        path: "blog/ingilizceyi-dizilerden-ogrenmek-isteyenler-icin-en-kolay-8-dizi",
        name: "TRblogDiziler",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blogDiziler" */ "../views/Blog/Diziler.vue"
          ),
      },
      {
        path: "blog/ingilizce-dizi-ve-filmlerde-en-sik-kullanilan-5000-kelime",
        name: "TRblog5000Kelime",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/5000Kelime.vue"
          ),
      },
      {
        path: "blog/a1-seviye-ingilizce-kelimeler-ve-anlamlari",
        name: "TRblogA1SeviyeKelime",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/A1Kelimeler.vue"
          ),
      },
      {
        path: "blog/yds-yokdil-sinavlarinda-en-cok-cikmis-600-kelime-listesi",
        name: "TRYokdil600",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/Yokdil600.vue"
          ),
      },
      {
        path: "premium",
        name: "TRpremium",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "premium" */ "../views/Premium.vue"),
      },
      {
        path: "premium/isleniyor",
        name: "TRPremiumProcessing",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Processing.vue"
          ),
      },
      {
        path: "premium/odemehata",
        name: "TRPremiumError",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Error.vue"
          ),
      },
      {
        path: "hesabim/aktivasyon/:code",
        name: "TRAktivasyon",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Aktivasyon" */ "../views/MailActivation.vue"
          ),
      },
      {
        path: "hesabim/sifre-yenile/:code",
        name: "TRPasswordRefresh",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PasswordRefresh" */ "../views/PasswordRefresh.vue"
          ),
      },
      {
        path: "satin-alma-kosullari",
        name: "TRUseInstructions",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/PurchaseConditions.vue"
          ),
      },
    ],
  },

  // DİĞER DİLLER
  {
    path: "/:lang(en)",
    component: RouterView,
    children: [
      {
        path: "",
        name: "home",
        component: Home,
      },
      {
        path: "tv-series",
        name: "SeriesList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "SeriesList" */ "../views/SeriesList.vue"
          ),
      },
      {
        path: "movies",
        name: "MoviesList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MoviesList.vue"
          ),
      },
      {
        path: "tv-series/:url",
        name: "SeriesDetail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "SeriesDetail" */ "../views/SeriesDetail.vue"
          ),
      },
      {
        path: "movies/:url",
        name: "MovieDetail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "MoviesList" */ "../views/MovieDetail.vue"
          ),
      },
      {
        path: "auth",
        name: "auth",
        beforeEnter: (to, from, next) => {
          if (!store.getters.isAuthenticated) {
            next();
          } else {
            next("/account");
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/Auth.vue"),
      },
      {
        path: "account",
        name: "hesabim",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "hesabim" */ "../views/Account.vue"),
      },
      {
        path: "wordlist/:url",
        name: "GeneratedList",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "GeneratedList" */ "../views/GeneratedList.vue"
          ),
      },
      {
        path: "known-list/:url",
        name: "KnownList",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "KnownList" */ "../views/KnownList.vue"),
      },
      {
        path: "quiz",
        name: "quiz",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "QuizMain" */ "../views/QuizMain.vue"),
      },
      {
        path: "contact",
        name: "iletisim",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
      },
      {
        path: "about",
        name: "hakkimizda",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "About" */ "../views/AboutEN.vue"),
      },
      {
        path: "privacy-policy",
        name: "gizlilikPolitikasi",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/PrivacyPolicyEN.vue"
          ),
      },
      {
        path: "use-conditions",
        name: "kullanimKosullari",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "gizlilikPolitikasi" */ "../views/UseConditionsEN.vue"
          ),
      },
      {
        path: "how-does-it-work",
        name: "NasilCalisir",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/UseInstructionsEN.vue"
          ),
      },
      {
        path: "blog",
        name: "blog",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "BlogMain" */ "../views/Blog/en/MainEN.vue"
          ),
      },
      {
        path: "blog/how-to-learn-english-with-tv-series",
        name: "blogRehber",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "BlogRehber" */ "../views/Blog/en/RehberEN.vue"
          ),
      },
      {
        path: "blog/best-tv-series-for-learning-english",
        name: "blogDiziler",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blogDiziler" */ "../views/Blog/en/DizilerEN.vue"
          ),
      },
      {
        path: "blog/tv-series-and-movies-vocabulary",
        name: "blog5000Kelime",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/5000KelimeEN.vue"
          ),
      },
      {
        path: "blog/a1-level-vocabulary",
        name: "blogA1SeviyeKelime",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/A1KelimelerEN.vue"
          ),
      },
      {
        path: "blog/english-exam-vocabulary",
        name: "Yokdil600",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "blog5000Kelime" */ "../views/Blog/en/Yokdil600EN.vue"
          ),
      },
      {
        path: "premium",
        name: "premium",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "/auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "premium" */ "../views/Premium.vue"),
      },
      {
        path: "premium/isleniyor",
        name: "PremiumProcessing",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Processing.vue"
          ),
      },
      {
        path: "premium/odemehata",
        name: "PremiumError",
        beforeEnter: (to, from, next) => {
          if (store.getters.isAuthenticated) {
            next();
          } else {
            next({ path: "auth", query: { authLink: "login" } });
          }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PremiumProcessing" */ "../components/Premium/Error.vue"
          ),
      },
      {
        path: "hesabim/aktivasyon/:code",
        name: "Aktivasyon",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "Aktivasyon" */ "../views/MailActivation.vue"
          ),
      },
      {
        path: "hesabim/sifre-yenile/:code",
        name: "PasswordRefresh",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PasswordRefresh" */ "../views/PasswordRefresh.vue"
          ),
      },
      {
        path: "terms-of-service",
        name: "UseInstructions",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "UseInstructions" */ "../views/PurchaseConditionsEN.vue"
          ),
      },
    ],
  },

  // {
  //  path: "/youtube",
  //  name: "youtube",
  //  component: () =>
  //   import(/* webpackChunkName: "youtube" */ "../views/Youtube.vue"),
  // },
  // Yönlendirmeler
  { path: "/ingilizce-dizilerin-kelime-ve-anlamlari", redirect: "/dizi" },
  { path: "/ingilizce-filmlerin-kelime-ve-anlamlari", redirect: "/film" },
  { path: "/tanitim", redirect: "/nasil-calisir" },
  { path: "/hesap/kullanimKosullari", redirect: "/" },
  {
    // old https://diziyleogren.com/dizi/ingilizce/the-walking-dead-kelimeler-ve-anlamlari
    // new https://diziyleogren.com/dizi/the-walking-dead
    path: "/dizi/ingilizce/:oldURL",
    redirect: (to) => {
      return {
        path:
          "/dizi/" + to.params.oldURL.replace("-kelimeler-ve-anlamlari", ""),
      };
    },
  },

  {
    // old https://diziyleogren.com/film/ingilizce/forest-gump-kelimeler-ve-anlamlari
    // new https://diziyleogren.com/film/forest-gump
    path: "/film/ingilizce/:oldURL",
    redirect: (to) => {
      return {
        path:
          "/film/" + to.params.oldURL.replace("-kelimeler-ve-anlamlari", ""),
      };
    },
  },

  {
    // old https://diziyleogren.com/Liste/Listem/ilk1000
    // new https://diziyleogren.com/liste/ilk1000
    path: "/Liste/Listem/:oldURL",
    redirect: (to) => {
      return {
        path: "/liste/" + to.params.oldURL,
      };
    },
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
