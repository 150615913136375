<template>

  <Head>
    <meta name="description" :content="$t('meta.home_description')" />
    <meta name="title" :content="$t('meta.hone_title')" />
    <title>{{ $t("meta.hone_title") }}</title>
  </Head>
  <div class="home">
    <div class="splash-image">

    </div>
    <div class="splash-content">
      <div class="splash-text">
        <h1>{{ $t("home.splash_title") }}</h1>
      </div>
      <router-link :to="{ path: $store.getters.getLocaleLink('/dizi') }" class="text-decoration-none">
        <div class="large-purple-button">
          <a>{{ $t("home.splash_button") }}</a>
        </div>
      </router-link>

    </div>

    <div class="my-container">
      <div class="left-side-bar"></div>
      <div class="content-bar">

        <div class="how-it-works">
          <div class="header-block">
            <span id="tanitim">{{ $t("home.how_it_works") }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="360" height="4" viewBox="0 0 360 4" fill="none">
              <path d="M0 1.85425H360" stroke="#B7B7B7" stroke-width="3" />
            </svg>
          </div>
          <div class="card-block">
            <div class="card-text-item">{{ $t("home.box_1") }}
            </div>
            <div class="card-image-item">
              <Vue3Lottie :animationData="Home1" :height="300" :width="300" />
            </div>
          </div>
          <div class="line-break-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="706" height="6" viewBox="0 0 706 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM705.887 3L703 0.113249L700.113 3L703 5.88675L705.887 3ZM3 3.5H703V2.5H3V3.5Z"
                fill="#8887C6" />
            </svg>
          </div>

          <div class="card-block">
            <div class="card-image-item">

              <Vue3Lottie :animationData="Home2" :height="300" :width="300" />
            </div>
            <div class="card-text-item">{{ $t("home.box_2") }}

            </div>
          </div>
          <div class="line-break-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="706" height="6" viewBox="0 0 706 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM705.887 3L703 0.113249L700.113 3L703 5.88675L705.887 3ZM3 3.5H703V2.5H3V3.5Z"
                fill="#8887C6" />
            </svg>
          </div>

          <div class="card-block">
            <div class="card-text-item">{{ $t("home.box_3") }}

            </div>
            <div class="card-image-item">

              <Vue3Lottie :animationData="Home3" :height="300" :width="300" />
            </div>
          </div>
          <div class="line-break-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="706" height="6" viewBox="0 0 706 6" fill="none">
              <path
                d="M0.113249 3L3 5.88675L5.88675 3L3 0.113249L0.113249 3ZM705.887 3L703 0.113249L700.113 3L703 5.88675L705.887 3ZM3 3.5H703V2.5H3V3.5Z"
                fill="#8887C6" />
            </svg>
          </div>

          <div class="card-block">
            <div class="card-image-item">

              <Vue3Lottie :animationData="Home4" :height="300" :width="300" />
            </div>
            <div class="card-text-item">{{ $t("home.box_4") }} </div>
          </div>

          <div class="flex-column-container">
            <span>{{ $t("home.congrats") }}</span>
            <p>{{ $t("home.you_are_ready") }}</p>
            <Vue3Lottie :animationData="Home5" :height="300" :width="300" />
            <small class="warning">{{ $t("home.alert") }}</small>
          </div>

        </div>

      </div>
      <div class="right-side-bar"></div>
    </div>


    <div class="flex-row-container">
      <div class="flex-row-inner-container fact-left">
        <div class="flex-row-inner-container">
          <img src="../assets/Home/series.svg" alt="">
          <div class="h2">
            <vue3-autocounter ref='counter' :startAmount='0' :endAmount='seriesLength' :duration='3' :autoinit='true' />
          </div>

          <p>{{ $t("home.number_of_series") }}</p>
        </div>
        <div class="flex-row-inner-container">
          <img src="../assets/Home/movies.svg" alt="">
          <div class="h2">
            <vue3-autocounter ref='counter2' :startAmount='0' :endAmount='moviesLength' :duration='3'
              :autoinit='true' />
          </div>
          <p>{{ $t("home.number_of_movies") }}</p>
        </div>
      </div>
      <div class="flex-row-inner-container fact-right">
        <p>{{ $t("home.new_series_message") }}</p>
      </div>
    </div>


    <div class='flex-column-container'>
      <router-link :to="{ path: $store.getters.getLocaleLink('/dizi') }" class="text-decoration-none">
        <div class="large-purple-button">
          <a> {{ $t("home.splash_button") }}</a>
        </div>
      </router-link>
      <p v-if="$i18n.locale != 'en'" class="h4">Veya</p>
      <a v-if="$i18n.locale != 'en'" target="_blank"
        href="https://play.google.com/store/apps/details?id=com.diziyleogren.diziyleogren"><img style="width: 190px"
          alt="Google Play'den alın" src='../assets/Home/google-play-badge.svg' /></a>
      <a v-if="$i18n.locale != 'en'" target="_blank" href="https://apps.apple.com/tr/app/diziyleogren/id6444682457"><img
          style="width: 190px" alt="App Store'dan alın" src='../assets/Home/app-store-badge.svg' /></a>
    </div>
  </div>
</template>
<script>
import Vue3Autocounter from 'vue3-autocounter';
import { Vue3Lottie } from 'vue3-lottie'
import Home1 from '../assets/Home/Home1.json'
import Home2 from '../assets/Home/Home2.json'
import Home3 from '../assets/Home/Home3.json'
import Home4 from '../assets/Home/Home4.json'
import Home5 from '../assets/Home/Home5.json'

export default {
  data() {
    return {
      Home1,
      Home2,
      Home3,
      Home4,
      Home5

    }
  },
  components: {
    Vue3Autocounter,
    Vue3Lottie
  },
  methods: {
    UpdateModal() {
      this.$store.commit('UpdateshowModal', true);
      this.$store.commit('UpdateSignUp', true);
      this.$store.commit('UpdateSignIn', false);
    },
  },
  created() {
  },
  computed: {
    seriesLength() {
      return this.$store.getters.getDOData.data.series.length
    },
    moviesLength() {
      return this.$store.getters.getDOData.data.movies.length
    },
  }

}
</script>
<style>
.home {
  position: relative;
  margin-bottom: 52px;
}

.splash-image {
  width: 100%;
  height: calc(100vh - 92px);
  background-image: url("../assets/Home/index-splash.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 1000px rgba(0, 0, 0, 0.5);
  position: relative;

}

.splash-content {
  width: 100%;
  height: calc(100vh - 92px);
  display: flex;
  flex-direction: column;
  gap: 78px;
  position: absolute;
  top: 0px;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 78px;
}



.splash-content .splash-text h1 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 48px;
  font-weight: 600;
  width: 50%;
  text-align: center;
  margin: auto;
}

.how-it-works {
  width: 100%;
}

.how-it-works .header-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.how-it-works .header-block span {
  color: var(--Gray, #B7B7B7);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-block {
  display: flex;
  flex-direction: row;
  gap: 128px;
  margin-top: 64px;
  justify-content: center;
}

.card-text-item {
  display: flex;
  width: 400px;
  height: 256px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: var(--Primary, #8887C6);
  text-align: center;
  font-family: Podkova;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 16px;
  background: var(--Lightest-Gray, #FBFBFB);

  box-shadow: 16px 16px 16px 0px #B7B7B7;
  transition: background 0.8s;

}

.card-text-item:hover {

  background: var(--Light-Tertiary, #FFFAEB);

}

.card-image-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 256px;
  flex-shrink: 0;
}

.line-break-item {
  display: flex;
  justify-content: center;
  margin-top: 64px;
}

.flex-column-container {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  gap: 32px;
}

.flex-column-container span {
  color: var(--Primary, #8887C6);
  text-align: center;
  font-family: Podkova;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.flex-column-container p {
  color: var(--Primary, #8887C6);
  text-align: center;
  font-family: Podkova;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  margin-top: 16px;
}

.flex-row-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  gap: 16px;
  height: 200px;
}

.fact-left,
.fact-right {
  text-align: center;
  font-family: Podkova;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px;
  width: 100%;

}

.fact-left {
  color: var(--LightYellow, #FFFAE9);
  background: #8887C6;
}

.fact-right {
  color: #8887C6;
  background: var(--LightYellow, #FFFAE9);
}

.warning {
  color: #8887C6;
  text-align: center;
}


@media (max-width: 1000px) {

  .splash-content .splash-text h2 {
    font-size: 32px;
  }

  .splash-image {
    height: calc(100vh - 70px);
  }

  .card-block {
    position: relative;
    margin-top: 32px;
  }


  .card-text-item {
    width: 294px !important;
    height: 280px !important;
    font-size: 20px !important;
    justify-content: flex-start !important;
    padding-top: 16px;
  }

  .card-image-item {
    position: absolute;
    top: 32px;
  }

  .line-break-item {
    display: none !important;
  }

  .header-block {
    margin-left: 24px;
  }

  .header-block #tanitim {
    font-size: 20px;
  }

  .header-block svg {
    width: 180px;
  }

  .flex-column-container span {
    font-size: 36px !important;
  }

  .flex-column-container p {
    font-size: 20px !important;
  }

  .flex-column-container Vue3Lottie {
    width: 142px !important;
  }

  .flex-row-container {
    flex-direction: column !important;
  }

  .fact-right p {
    font-size: 36px;
  }





}

@media (max-width: 700px) {
  .splash-image {
    background-image: url("../assets/Home/mobile-splash.jpg");
    background-position: center;
  }

  .splash-content {
    gap: 150px;
  }

  .splash-content .splash-text h1 {
    font-size: 30px;
  }

  .fact-right p {
    font-size: 20px;
  }

  .fact-left p {
    font-size: 12px;
  }

  .flex-row-inner-container img {
    width: 40px;
  }

}
</style>